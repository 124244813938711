/* eslint-disable wrap-iife */
/* eslint-disable import/prefer-default-export */
/* eslint-env browser */
const copyToClipboard = (str: string): Promise<void> => {
  // eslint-disable-next-line no-console
  return navigator.clipboard.writeText(str).then(() => console.log(`${str} written to clipboard`));
};

const isElementWithClassNameInEventPath = (event: MouseEvent, className: string): boolean => {
  let found = false;
  const path = event.composedPath();
  path.forEach((p: EventTarget): void => {
    if ((p as HTMLElement).classList) {
      if ((p as HTMLElement).classList.contains(className)) {
        found = true;
      }
    }
  });
  return found;
};

const setMetaThemeColor = (): void => {
  const body = document.querySelector('body');
  if (body) {
    const bgColor = window.getComputedStyle(body).getPropertyValue('--color-background');
    const existingMeta = document.querySelector('meta[name="theme-color"]') as HTMLMetaElement;
    if (existingMeta) {
      existingMeta.content = bgColor;
    } else {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = bgColor;
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }
};

const getBaseFontSize = (): number => parseInt(window.getComputedStyle(document.documentElement).fontSize.replace('px', ''), 10);

// eslint-disable-next-line max-len
const convertRemToPixels = (rem: number): number => rem * 16;

const isViewportBelow = (mediaQuery: string): boolean => {
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const mediaQueryValue = convertRemToPixels(parseInt(getComputedStyle(document.documentElement).getPropertyValue(mediaQuery).replace('rem', ''), 10));
  return viewportWidth < mediaQueryValue;
};

const setScrollbarWidthVariable = (): void => {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.setProperty('--vertical-scrollbar-width', `${scrollbarWidth}px`);
};

const overflowsY = (element: HTMLElement): boolean => element.offsetHeight !== element.scrollHeight;
const overflowsX = (element: HTMLElement): boolean => element.offsetWidth !== element.scrollWidth;
// eslint-disable-next-line max-len
const elementOverflows = (element: HTMLElement): boolean => overflowsY(element) || overflowsX(element);

const setHorizontalScroll = (element: HTMLElement): void => {
  // Checks if the element already contains no-overflow
  if (element.classList.contains('horizontal-scroll--no-overflow')) {
    element.classList.remove('horizontal-scroll--no-overflow');
  }

  // Defines of content of horizontal scroll 
  let content = element.querySelector('.horizontal-scroll--check-overflow .tabbed-content__section:not([hidden]) .horizontal-scroll__scrollable-content');
  if (content === null && !content) {
    content = element.querySelector('.horizontal-scroll--check-overflow .horizontal-scroll__scrollable-content');
  }

  // Checks if no-overflow should be added or not depending on content width
  if (content) {
    if (!overflowsX(content as HTMLElement)) {
      element.classList.add('horizontal-scroll--no-overflow');
      content?.removeAttribute('tabindex');
    } else {
      content?.setAttribute('tabindex', '0');
    }
  }
};

const focusableSelector = 'a[href]:not([tabindex="-1"]), area[href]:not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), iframe:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"]), [contentEditable=true]:not([tabindex="-1"])';

export {
  copyToClipboard,
  isElementWithClassNameInEventPath,
  getBaseFontSize,
  setMetaThemeColor,
  isViewportBelow,
  convertRemToPixels,
  setScrollbarWidthVariable,
  overflowsX,
  overflowsY,
  elementOverflows,
  setHorizontalScroll,
  focusableSelector,
};
